<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              @click="Export"
            >
              Export
            </b-button> -->
          </b-form-group>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="search_val"
                type="search"
                placeholder="Search Username"
                @keyup.enter="getUsers()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!search_val"
                  @click="search_val = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'manage-agent' , params: { id: data.item.id }})"
            />
            <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            />
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="HardDriveIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'History-Play', params: { id: data.item.username }})"
            />
            <b-tooltip
              title="History Play"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-edit"
        no-close-on-backdrop
        title="Edit Member"
        ok-only
        ok-title="Save"
        @ok="update_member"
      >
        <b-form-group
          label="Name"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Surname"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_surname"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Password"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_password"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="ธนาคาร"
          label-for="vi-first-name"
        >
          <v-select
            v-model="edit_bank"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="banklist"
            :reduce="name => name.bankcode"
          />
        </b-form-group>
        <b-form-group
          label="เลขบัญชี"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_acc_no"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-promo"
        title="Promotion"
        no-close-on-backdrop
        ok-title="save"
        @ok="Submit_promotion"
      >
        <b-form-group>
          <v-select
            v-model="pro_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="prolist"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'prefix', label: 'prefix' },
        { key: 'site', label: 'site' },
        { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      Interval: null,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    this.Interval = setInterval(() => {
      this.getAgent()
    }, 30000)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getAgent()
  },
  methods: {
    Export() {
      window.open(
        'https://api.rmtlotto.com/api/export',
        // 'https://api.rmtlotto.com/api/export',
      )
    },
    addpro(id) {
      this.update_id = id
      // console.log(id)
    },
    editmember(data) {
      this.edit_id = data.id
      this.edit_name = data.name
      this.edit_surname = data.surname
      this.edit_bank = data.bank
      this.edit_acc_no = data.acc_no
      // console.log(data)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getAgent() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
      }
      await this.$http
        .get('https://api.rmtlotto.com/api/agent', { params })
        .then(response => {
          this.show = false
          // this.depositdata = response.data.data
          // this.depositdata.forEach(items => {
          //   this.getBalance(items.username, index)
          //   index += 1
          // })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.depositdata[index].updated_at = response.data.balance
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
